import dynamic from 'next/dynamic';
import Image, { ImageProps } from 'next/image';

import { CardOptions, nameDataMatrixComponentCard } from 'matrix.d';
import EventCard from '@sympla/sympla-components/EventCard';
import { DEFAULT_IMAGE_BASE64 } from 'constants/index';

const CollectionCard = dynamic(() => import('components/new-components/Shared/CollectionCard/CollectionCard'), { ssr: false });
const OrganizerCard = dynamic(() => import('components/new-components/Shared/OrganizerCard/OrganizerCard'), { ssr: false });
const CityCard = dynamic(() => import('components/new-components/Shared/CityCard/CityCard'), { ssr: false });
const PlaceCard = dynamic(() => import('components/new-components/Shared/PlaceCard/PlaceCard'), { ssr: false });
const BannerOneStopShop = dynamic(() => import('components/new-components/Shared/BannerOneStopShop/BannerOneStopShop'), { ssr: false });
const BannerCard = dynamic(() => import('components/new-components/Shared/BannerCard/BannerCard'), { ssr: false });

export const cardOptions: CardOptions = {
  'event-card': {
    component: (props) => (
      <EventCard
        {...props}
        ImageComponent={(imageProps: ImageProps) => (
          <Image
            {...imageProps}
            priority={imageProps.loading === 'eager'}
            width={260}
            height={140}
            sizes="(max-width: 640px) 50vw, (max-width: 830px) 33vw, 25vw"
            quality={75}
            placeholder="blur"
            blurDataURL={DEFAULT_IMAGE_BASE64}
          />
        )}
      />
    ),
    hasData: true,
    nameData: nameDataMatrixComponentCard.DATA,
    urn: 'id',
    initialCardsQty: 4,
    urlSeeAll: 'see-all-event',
    paramsToBuildUrl: (uuid: string) => uuid,
  },
  'recent-card': {
    component: (props) => (
      <EventCard
        {...props}
        ImageComponent={(imageProps: ImageProps) => (
          <Image
            {...imageProps}
            priority={imageProps.loading === 'eager'}
            width={260}
            height={140}
            sizes="(max-width: 640px) 50vw, (max-width: 830px) 33vw, 25vw"
            quality={75}
            placeholder="blur"
            blurDataURL={DEFAULT_IMAGE_BASE64}
          />
        )}
      />
    ),
    hasData: true,
    nameData: nameDataMatrixComponentCard.DATA,
    urn: 'id',
    initialCardsQty: 4,
    urlSeeAll: 'see-all-event',
    paramsToBuildUrl: (uuid: string) => uuid,
  },
  'collection-card': {
    component: CollectionCard,
    hasData: true,
    nameData: nameDataMatrixComponentCard.MATRICES,
    urn: 'slug',
    initialCardsQty: 8,
    urlSeeAll: 'see-all-collection',
    paramsToBuildUrl: (uuid: string) => uuid,
  },
  'venue-card': {
    component: PlaceCard,
    hasData: true,
    nameData: nameDataMatrixComponentCard.MATRICES,
    urn: 'url',
    initialCardsQty: 5,
    urlSeeAll: 'see-all-venue',
    paramsToBuildUrl: (uuid: string) => uuid,
  },
  'city-card': {
    component: CityCard,
    hasData: true,
    nameData: nameDataMatrixComponentCard.MATRICES,
    urn: 'slug',
    initialCardsQty: 5,
    urlSeeAll: 'see-all-city',
    paramsToBuildUrl: (uuid: string) => uuid,
  },
  'organizer-card': {
    component: OrganizerCard,
    hasData: true,
    nameData: nameDataMatrixComponentCard.DATA,
    urn: '',
    initialCardsQty: 6,
    urlSeeAll: 'see-all-organizer',
    paramsToBuildUrl: (uuid: string) => uuid,
  },
  'banner-card': {
    component: BannerCard,
    hasData: true,
    nameData: nameDataMatrixComponentCard.DATA,
    urn: '',
    initialCardsQty: 4,
    paramsToBuildUrl: (uuid: string) => uuid,
  },
  'banner-oss': {
    component: BannerOneStopShop,
    hasData: true,
    nameData: nameDataMatrixComponentCard.DATA,
    urn: '',
    initialCardsQty: 4,
    paramsToBuildUrl: (uuid: string) => uuid,
  },
};
