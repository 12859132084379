/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */

'use client';

import {
  useState, useEffect, Suspense, useCallback,
} from 'react';
import dynamic from 'next/dynamic';
import { usePathname, useSearchParams } from 'next/navigation';

import { createPropsToTrackingMatrix } from 'components/Integration/createPropsToTrackingMatrix';

import searchCategoryType from 'services/searchCategoryType/searchClient';
import { createLinkToRedirect } from 'utils/createLinkToRedirect';

import {
  CardPreviousView, CollectionData, CollectionMatrix, MatrixComponent,
} from 'matrix.d';
import { Location } from 'location';
import { CarouselWrapperSkeleton } from './CarouselWrapper.skeleton';

import * as styles from './CarouselWrapper.css';
import { cardOptions } from './cardOptions';

const IconArrowLeft = dynamic<IconProps>(() => import('@sympla/sympla-components/NavigationIcons').then((mod) => mod.IconArrowLeft), {
  ssr: false,
});
const IconArrowRight = dynamic<IconProps>(() => import('@sympla/sympla-components/NavigationIcons').then((mod) => mod.IconArrowRight), {
  ssr: false,
});
const Carousel = dynamic(() => import('@sympla/sympla-components/HomeCarousel'), { ssr: false });
const Button = dynamic(() => import('@sympla/sympla-components/Button'), { ssr: false });
const ShowMoreCard = dynamic(() => import('components/new-components/Shared/ShowMoreCard/ShowMoreCard'), { ssr: false });

interface CarouselWrapperProps {
  component: MatrixComponent;
  userLocation: Location | null;
  prefixTrackingName: string;
  matrixUuid: string;
  matrixType: string;
  cardPreviousView: CardPreviousView;
  specialUUIDs?: string[];
}

export function CarouselWrapper({
  component,
  userLocation,
  prefixTrackingName,
  matrixUuid,
  matrixType,
  cardPreviousView,
  specialUUIDs = [],
}: CarouselWrapperProps) {
  const [controls, setControls] = useState({
    next: () => {},
    prev: () => {},
    slidesTotal: 0,
  });
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [btnArrows, setBtnArrows] = useState({
    beginning: true,
    end: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [CardComponentsDataList, setCardComponentsDataList] = useState<CollectionData[] | CollectionMatrix[]>(
    component?.response && cardOptions[component.response_type] ? component.response[cardOptions[component.response_type]!.nameData] || [] : [],
  );
  useEffect(() => {
    if (specialUUIDs.includes(component.uuid) || !component.response) {
      const { service, service_params } = component;
      if (component.uuid === 'recentes' && service_params?.events_ids?.length <= 0) return;
      const d = searchParams?.get('d') as string | undefined;
      const p = searchParams?.get('p') as string | undefined;

      setIsLoading(true);
      searchCategoryType({
        ...service_params,
        d,
        p,
        service,
      })
        .then((response) => {
          const nameData = cardOptions[component.response_type]?.nameData;
          if (nameData) {
            setCardComponentsDataList(response[nameData] || []);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [component]);

  const Card = cardOptions[component.response_type];
  if (!Card) return null;

  const CardComponent = Card.component;

  const handleLoadCarouselData = useCallback(() => {
    const carouselPayload = CardComponentsDataList.map((item, index: number) => {
      const skipCards = ['banner-card', 'banner-login', 'banner-oss'];
      const finalCard = component.response_type.replace('-card', '');
      const itemValue = item[Card.urn as keyof typeof item];
      const paramValue = Card.urn in item && typeof itemValue !== 'object' ? String(itemValue) : '';
      const utmSource = searchParams?.get('utm_source');
      const utmCampaign = searchParams?.get('utm_campaign');

      const linkUrl = skipCards.includes(component.response_type) ? '' : createLinkToRedirect({
        param: paramValue,
        routeType: finalCard === 'event' ? `event-${'company' in item ? item.company : ''}` : finalCard,
        item: {
          ...item,
          urn: Card.paramsToBuildUrl ? Card.paramsToBuildUrl(component.uuid) : '',
        },
        isDirectory: component.is_directory,
        userLocation,
        forwardParams: { ...component.forward_params, utm_source: utmSource, utm_campaign: utmCampaign },
      });

      return {
        slide: (
          <CardComponent
            key={component.uuid}
            href={linkUrl}
            loadingImage={index >= 4 ? 'lazy' : 'eager'}
            {...createPropsToTrackingMatrix(index, {
              trackingPrefix: prefixTrackingName,
              matrizTemplate: matrixUuid || '',
              componentTemplate: component.uuid,
              dataLocation: userLocation,
              cardType: component.response_type,
              cardPreviousView,
              templateType: component.response_type || component.template,
              serviceParams: component.service_params,
            })}
            {...item}
            url={linkUrl}
          />
        ),
      };
    });

    if (component.show_more && Card.urlSeeAll && component.response_type !== 'banner-card') {
      carouselPayload.push({
        slide: (
          <ShowMoreCard
            key="show-more"
            cardType={component.response_type}
            lang="PT"
            href={createLinkToRedirect({
              param: matrixType,
              routeType: Card.urlSeeAll,
              item: {
                urn: Card.paramsToBuildUrl ? Card.paramsToBuildUrl(component.uuid) : '',
                ordem: component?.service_params?.sort || '',
              },
              isDirectory: component.is_directory,
              userLocation,
              pathname: pathname ?? '',
            })}
          />
        ),
      });
    }

    return carouselPayload;
  }, [CardComponentsDataList, component]);

  if (isLoading) {
    return (
      <div
        key={component.uuid}
        id={`section-container-${component.template}-${component.uuid}`}
        data-section-name={`section-container-${component.template}`}
        className={`${styles.sectionContainer} animated fadeIn`}
      >
        <div className={styles.sectionContainerHeader}>
          <h2 className={styles.sectionContainerTitle}>
            {component.label}
          </h2>
        </div>
        <div className={`${styles.sectionContainerCarousel[component.response_type]} animated fadeIn`}>
          <CarouselWrapperSkeleton
            untitled
            type={component.response_type}
            numberOfComponents={component.response_type === 'organizer-card' ? 2 : 4}
          />
        </div>
      </div>
    );
  }

  if (CardComponentsDataList.length <= 0) return null;

  return (
    <div
      key={component.uuid}
      id={`section-container-${component.template}-${component.uuid}`}
      data-section-name={`section-container-${component.template}`}
      className={`${styles.sectionContainer} animated fadeIn`}
    >
      <div className={styles.sectionContainerHeader}>
        {component.label && <h2 className={styles.sectionContainerTitle}>{component.label}</h2>}

        {CardComponentsDataList.length > Card.initialCardsQty && (
          <div className={styles.sectionContainerButtons}>
            <div className={styles.sectionContainerButtonSeeAll} style={{ display: component.show_more ? 'flex' : 'none' }}>
              <a
                className={styles.sectionContainerButtonLink}
                href={createLinkToRedirect({
                  param: matrixType,
                  routeType: Card.urlSeeAll || '',
                  item: {
                    urn: Card.paramsToBuildUrl ? Card.paramsToBuildUrl(component.uuid) : '',
                    ordem: component?.service_params?.sort || '',
                  },
                  isDirectory: component.is_directory,
                  userLocation,
                  pathname: pathname ?? '',
                })}
              >
                Ver tudo
              </a>
            </div>

            <div className={styles.sectionContainerButtonsIcons}>
              <Button
                variant="text"
                icon={<IconArrowLeft width={24} height={24} />}
                onClick={controls.prev}
                disabled={btnArrows.beginning}
                tabIndex={-1}
                aria-label="Previous"
                className={styles.sectionContainerButton}
              />

              <Button
                variant="text"
                icon={<IconArrowRight width={24} height={24} />}
                onClick={controls.next}
                disabled={btnArrows.end}
                tabIndex={-1}
                aria-label="Next"
                className={styles.sectionContainerButton}
              />
            </div>
          </div>
        )}
      </div>

      <div className={`${styles.sectionContainerCarousel[component.response_type]} animated fadeIn`}>
        <Suspense fallback={(
          <CarouselWrapperSkeleton
            untitled
            type={component.response_type}
            numberOfComponents={component.response_type === 'organizer-card' ? 2 : 4}
          />
        )}
        >
          <Carousel
            id={`${component.order}`}
            key={component.order}
            type={component.response_type}
            options={handleLoadCarouselData()}
            controls={(payload) => setControls({ ...controls, ...payload })}
            updateArrows={(payload) => setBtnArrows(payload)}
          />
        </Suspense>
      </div>
    </div>
  );
}
