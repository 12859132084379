/* eslint-disable react/no-array-index-key */
import { Suspense } from 'react';
import { Location } from 'location.d';
import { CardPreviousView, MatrixComponent, nameDataMatrixComponentCard } from 'matrix.d';
import { MATRIX_TYPE } from 'constants/index';

import { CarouselWrapper } from './components/CarouselWrapper/CarouselWrapper';
import { CarouselWrapperSkeleton } from './components/CarouselWrapper/CarouselWrapper.skeleton';

import { container } from './Matrix.css';

interface MatrixProps {
  components: MatrixComponent[];
  userLocation: Location | null;
  cardPreviousView: CardPreviousView;
  matrixUuid: string;
  matrixType: string;
  specialUUIDs?: string[];
  prefixTrackingName?: string;
}

export function Matrix({
  components,
  userLocation,
  cardPreviousView,
  matrixUuid,
  matrixType,
  prefixTrackingName,
  specialUUIDs = [],
}: MatrixProps) {
  return (
    <div className={container}>
      {components.map((component, index) => {
        const response = component.response?.[nameDataMatrixComponentCard.DATA] || component.response?.[nameDataMatrixComponentCard.MATRICES] || [];

        return (
          <Suspense
            key={`${component.uuid}-${index}`}
            fallback={(
              <CarouselWrapperSkeleton
                type={component.response_type}
                numberOfComponents={response?.length || 1}
              />
            )}
          >
            <CarouselWrapper
              key={`${component.uuid}-${index}`}
              component={component}
              prefixTrackingName={prefixTrackingName || matrixType || MATRIX_TYPE.MATRIX}
              matrixUuid={matrixUuid}
              matrixType={matrixType}
              cardPreviousView={cardPreviousView}
              userLocation={userLocation}
              specialUUIDs={specialUUIDs}
            />
          </Suspense>
        );
      })}
    </div>
  );
}
