import { ParamsMapSearch } from 'ParamsResult';

export type Sort = 'global-score-norm' | 'location_score' | 'day-trending-score' | 'start-date';

export interface HightLightResponse {
    featured_id: number | null,
    event_id: number | null,
    image_url: string,
    alt: string | '',
    link_page: string | '',
    place: string | '',
    type: 'EVENTO' | string,
    title: string | '',
    title_seo: string | '',
    location?: {
      city: string,
      name: string,
      state: string
    },
    end_date_formats?: {
      en: string
      es: string
      pt: string
    },
    start_date_formats?: {
      en: string
      es: string
      pt: string
    },
    startDate: string,
    endDate: string
}

interface ImageSet {
  mobile?: string[];
  app?: string;
  desktop?: string[];
  web?: string;
}

interface Banner {
  enabled: boolean;
  type_params: Record<string, unknown>;
  action: string;
  category: string;
  images: ImageSet;
  order: number;
  is_clickable: boolean;
  display_on: string[];
  title: string;
}

export type UserLocation = {
  city: string;
  coordinates: {
    lat: number;
    lon: number;
  };
  sk: number;
  slug: string;
};

interface ServiceParams {
  category: string;
  limit: number;
  token: string;
  events_ids: string;
  sort: Sort;
  location?: string;
}

export type CardPreviousView = { recents: string[] };

export type CardImages = {
  xs?: string;
  md?: string;
  lg?: string;
  original?: string;
  banner: string;
};

type ImageUrls = {
  icon: string;
  normal: string;
  banner: string;
  iconActive?: string;
  darkNormal?: string;
  web?: string;
};

export type CollectionMatrix = {
  collectionId?: string;
  template: string;
  label: string;
  seoTitle: string;
  slug: string;
  uuid: string;
  description: string;
  seoDescription: string;
  images: ImageUrls;
};

export type CollectionData = {
  id: number;
  week_trending_score: number;
  month_trending_score: number;
  sum_ticket_price: number;
  is_published: boolean;
  type: string;
  uuid: string;
  seasonality_sk: number;
  trending_score: number;
  start_date_formats: {
    pt: string;
    en: string;
    es: string;
  };
  company?: string;
  avg_ticket_price: number;
  collections: number[];
  theme_sk: number;
  sum_ticket_avaiable: number;
  id: number;
  state: string;
  images: {
    original: string;
    xs: string;
    lg: string;
  };
  global_score_norm: number;
  end_date_utc: string;
  tags: string[];
  num_ticket_lot: 3;
  end_date_formats: {
    pt: string;
    en: string;
    es: string;
  };
  name: string;
  public_name: string;
  managers: [];
  format_name: string;
  collection_name: string[];
  end_date: string;
  city: string;
  timezone: string;
  visits_score: number;
  title: string;
  sales_score: number;
  public_sk: number;
  subtheme_name: string;
  day_trending_score: number;
  subtheme_sk: number;
  organizer_id: number;
  company: string;
  format_sk: number;
  seasonality_name: string;
  start_date: string;
  start_date_utc: string;
  global_score: number;
  duration_type: string;
  url: string;
  organizer: {
    image: string;
    name: string;
    id: string;
    url: string;
    email: string;
  };
  category_prim: {
    name: string;
  };
  is_free: false;
  theme_name: string;
  location: {
    country: string;
    address: string;
    address_alt: string;
    city: string;
    address_num: string;
    name: string;
    lon: number;
    state: string;
    neighborhood: string;
    zip_code: string;
    lat: number;
  };
  category_sec: {
    name: string;
  };
  trending_rank: number;
};

export enum nameDataMatrixComponentCard {
  DATA = 'data',
  MATRICES = 'matrices',
}

type CardOption = {
  component: React.ComponentType<any>;
  hasData: boolean;
  nameData: nameDataMatrixComponentCard;
  urn: keyof CollectionData | keyof CollectionMatrix | '';
  initialCardsQty: number;
  urlSeeAll?: string;
  paramsToBuildUrl?: (params: string) => string;
};

export type CardOptions = {
  'event-card': CardOption;
  'recent-card': CardOption;
  'collection-card': CardOption;
  'city-card': CardOption;
  'venue-card': CardOption;
  'organizer-card': CardOption;

  // 'ondemand'?: CardOption;
  'banner-card': CardOption;
  'banner-login'?: CardOption;
  'banner-oss': CardOption;
  sponsored?: CardOption;
};

export type TemplateType = 'slider' | 'sponsored';

export type ComponentResponse = {
  [nameDataMatrixComponentCard.DATA]?: CollectionData[];
  [nameDataMatrixComponentCard.MATRICES]?: CollectionMatrix[];
  limit?: number;
  total: number;
};

export type MatrixComponent = {
  service: string;
  template: string;
  is_directory: boolean;
  show_more: boolean;
  label: string;
  response_type: keyof CardOptions;
  uuid: string;
  order: number;
  service_params: ServiceParams;
  forward_params?: ForwardParams;
  response?: ComponentResponse;
  seo_description?: string;
  seo_title?: string;
};

export type MatrixResult = {
  template: string;
  label: string;
  seo_title: string;
  slug: string;
  uuid: string;
  description: string;
  seo_description: string;
  components: MatrixComponent[];
  components_total: number;
  images?: {
    icon: string;
    normal: string;
    banner: string;
    'icon-active': string;
  };
};

export type MatrixParams = {
  category: 'page' | string;
  include_response: boolean;
  components_limit: number;
  components_page: number;
  limit: number;
  page: number;
  service: string;
  uuid: string;
  afterComponentsCount: number;
  matrix_type: string;
  user_id: number;
  events_ids?: string;
  sort: Sort;
  location: string;
  d?: string;
  p?: string;
  paramsMapSearch?: ParamsMapSearch;
  seasonalities?: string;
  themes?: string;
};
