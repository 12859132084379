import { browserApi } from 'lib/httpClient';
import { MatrixParams } from 'matrix';
import { SearchCategoryTypesResult } from 'searchCategoryTypes';

async function searchCategoryType(params: Partial<MatrixParams>): Promise<SearchCategoryTypesResult> {
  const { data } = await browserApi.get<SearchCategoryTypesResult>('/search/category-type', {
    params,
  });
  return data;
}

export default searchCategoryType;
